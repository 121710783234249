@tailwind base;
@tailwind components;

@responsive {
  .bg-white-transparent {
    background: rgba(255,255,255, 0.8);
  }
}

@tailwind utilities;
